<template>
  <div class="hold-transition">
    <loading v-if="cargando || loading" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">
                  {{ accion }} Nominación
                  <b v-if="accion == 'Editar'">No. {{ form.id }}</b>
                </h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Hidrocarburos</router-link>
                  </li>
                  <li class="breadcrumb-item active">Nominaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div :class="form.id ? 'col-md-8' : 'col-md-12'">
                <form onsubmit="return false">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label>Fecha inicial</label>
                            <input
                              type="date"
                              :disabled="form.estado == 2"
                              class="form-control form-control-sm"
                              v-model="form.fecha_inicial"
                              :class="
                                $v.form.fecha_inicial.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label>Fecha final</label>
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              :disabled="form.estado == 2"
                              v-model="form.fecha_final"
                              :class="
                                $v.form.fecha_final.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Nombre</label>
                            <input
                              type="text"
                              :disabled="form.estado == 2"
                              v-model="form.nombre"
                              class="form-control form-control-sm"
                              :class="
                                $v.form.nombre.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-3" v-if="form.id">
                          <div class="form-group">
                            <label>Estado</label>
                            <select
                              :disabled="form.estado == 2"
                              v-model="form.estado"
                              @change="closeNominacion"
                              class="form-control form-control-sm"
                              required
                            >
                              <option
                                v-for="r in listas"
                                v-bind:key="r.id"
                                v-bind:value="r.numeracion"
                              >
                                {{ r.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3" v-if="form.id">
                          <div class="form-group">
                            <label for="">Tipos Productos</label>
                            <select
                              v-model="form.tipo_producto"
                              class="form-control form-control-sm"
                              required
                            >
                              <option
                                v-for="r in tipos_productoOption"
                                v-bind:key="r.id"
                                v-bind:value="r.numeracion"
                              >
                                {{ r.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Observación</label>
                            <textarea
                              v-model="form.observacion"
                              :disabled="form.estado == 2"
                              class="form-control form-control-sm"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <button
                          v-if="
                            !$v.form.$invalid &&
                              $store.getters.can(
                                'hidrocarburos.regaliasNominaciones.create'
                              )
                          "
                          type="button"
                          :disabled="form.estado == 2"
                          class="btn bg-gradient-primary shadow mr-1 "
                          @click="save"
                        >
                          <i class="fas fa-save"></i>
                          <div>Guardar</div>
                        </button>
                        <router-link to="/Hidrocarburos/NominacionesRegalias">
                          <button
                            type="button"
                            class="btn bg-gradient-secondary shadow "
                            @click="newItem = true"
                          >
                            <i class="fas fa-reply"></i>
                            <div>Volver</div>
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-4" v-if="form.id">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-md-6">
                        <h4 class="card-title mt-2 font-weight-bold ">
                          Archivos
                        </h4>
                      </div>
                      <div class="col-md-6">
                        <button
                          class="btn bg-gradient-info btn-sm float-right shadow"
                          type="button"
                          v-on:click="downloadFile"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.regaliasNominaciones.export'
                            )
                          "
                        >
                          <i class="fa fa-download" aria-hidden="true"></i>
                          Plantilla
                        </button>
                      </div>
                      <div class="card-body">
                        <input
                          :disabled="form.estado == 2"
                          type="file"
                          class="form-control"
                          id="fileDocu"
                          @change="fileUpload"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.regaliasNominaciones.import'
                            ) &&
                              $store.getters.can(
                                'hidrocarburos.regaliasNominaciones.edit'
                              )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="card card-outline card-navy collapsed-card"
              v-if="
                $store.getters.can('hidrocarburos.regaliasNominaciones.show') &&
                  form.id &&
                  detNominaciones.length > 0
              "
            >
              <div class="card-header">
                <h5 class="card-title">
                  <!-- Bloques - Sitios -->Detalles Nominación
                </h5>
                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>

              <div class="card-body" style="display: none">
                <!-- SECCION PARA CONSTRUCCION DEL ARBOL BLOQUE->SITIO -->
                <!-- <div class="row" align="center" v-show="flagArbol == false">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="">Buscar</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filter"
                        placeholder="Digite su busqueda"
                      />
                    </div>
                  </div>
                </div> -->

                <div class="row">
                  <div class="col-12">
                    <ul
                      class="
                        nav nav-sidebar
                        flex-column
                        nav-flat nav-child-indent
                      "
                      data-widget="treeview"
                      role="menu"
                      data-accordion="false"
                    >
                      <li
                        class="nav-item has-treeview col-12"
                        style="color: #6c757d;"
                        v-for="(base, index) in detNominaciones"
                        :key="index"
                      >
                        <a class="nav-link" style="color: #6c757d;">
                          <i class="nav-icon fas fa-circle"></i>
                          <p v-html="highlightMatches(base.nombre)">
                            <i class="right fas fa-angle-left"></i>
                          </p>
                        </a>

                        <ul class="nav nav-treeview">
                          <li
                            class="nav-item has-treeview"
                            v-for="sitio in base.sitios"
                            :key="sitio.id"
                          >
                            <a
                              class="nav-link"
                              style="color: #6c757d;"
                              v-if="sitio.det_nominacion_regalias.length > 0"
                            >
                              <div>
                                <div class="mb-3">
                                  <i class="nav-icon fas fa-circle"></i>
                                  <p>
                                    Nombre: {{ sitio.nombre }} | Codigo:
                                    {{ sitio.codigo }}
                                    <i class="right fas fa-angle-left"></i>
                                  </p>
                                </div>

                                <div class="col-md-12 table-responsive">
                                  <table
                                    class=" table table-bordered
                                    table-striped table-hover table-sm mb-3 shadow-sm"
                                  >
                                    <thead class="bg-gradient-dark">
                                      <tr>
                                        <th
                                          v-if="form.estado == 1"
                                          class="text-center"
                                        >
                                          Acciones
                                        </th>
                                        <th class="text-center">Api</th>
                                        <th class="text-center">
                                          Cargo Solicitante
                                        </th>
                                        <th class="text-center">
                                          Ruta regalías
                                        </th>
                                        <th class="text-center">Dependencia</th>
                                        <th class="text-center">
                                          Descripción Destino
                                        </th>
                                        <th class="text-center">
                                          Descripción Material
                                        </th>
                                        <th class="text-center">
                                          Descripción Origen
                                        </th>
                                        <th class="text-center">
                                          Fecha Cargue
                                        </th>
                                        <th class="text-center">
                                          Fecha Solicitud
                                        </th>
                                        <th class="text-center">
                                          Nombre Solicitante
                                        </th>
                                        <th class="text-center"># Vehículos</th>
                                        <th class="text-center"># Contacto</th>
                                        <th class="text-center">% bsw</th>
                                        <th class="text-center">
                                          Producto Líquido
                                        </th>
                                        <th class="text-center">
                                          Tipo Medición Destino
                                        </th>
                                        <th class="text-center">Tipo Tanque</th>
                                        <th class="text-center">
                                          Tipo Vehículo
                                        </th>
                                        <th class="text-center">
                                          Unidad Medida
                                        </th>
                                        <th class="text-center">Vol. Bruto</th>
                                        <th class="text-center">Observación</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr
                                        v-for="(detalle,
                                        indexDet) in sitio.det_nominacion_regalias"
                                        :key="indexDet"
                                      >
                                        <td v-if="form.estado == 1">
                                          <button
                                            class="btn bg-gradient-navy shadow-sm"
                                            data-toggle="modal"
                                            data-target="#modal_edit_detalle"
                                            @click="
                                              $refs.NominacionRegaliasModal.llenarModal(
                                                detalle
                                              )
                                            "
                                          >
                                            <i class="fas fa-edit"></i>
                                          </button>
                                          <button
                                            class="btn bg-gradient-danger shadow-sm"
                                            @click="deleteDet(detalle.id)"
                                          >
                                            <i class="fas fa-trash"></i>
                                          </button>
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.api }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.cargo_solicitante }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.ruta_regalia.nombre }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.dependencia }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.desc_destino }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.desc_material }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.desc_origen }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.fecha_cargue }}
                                          {{ detalle.hora_cargue }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.fecha_solicitud }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.nombre_solicitante }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.num_vehiculos }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.numero_contacto }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.porc_bsw }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.prod_liquido.nombre }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.tipo_medicion_destino }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.tipo_tanque }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.tipo_vehiculo.nombre }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.unidad_medida }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.vol_bruto }}
                                        </td>
                                        <td class="text-center">
                                          {{ detalle.observaciones }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal de agregar y editar -->
          <NominacionRegaliasModal ref="NominacionRegaliasModal" />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import NominacionRegaliasModal from "./NominacionRegaliasModal.vue";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "NominacionRegaliasForm",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    NominacionRegaliasModal,
  },
  data() {
    return {
      accion: "",
      cargando: false,
      bloqueSitios: [],
      filter: "",
      flagArbol: false,
      file: "",
      arrListasForm: {
        tipos_vehiculos: [],
        estados: [],
        empresas: [],
        totEmpresas: [],
      },
      buscarDet: "",
      detNominaciones: [],
      tipos_productoOption: [],
      form: {
        id: null,
        user_id: null,
        nombre: null,
        fecha_inicial: null,
        fecha_final: null,
        tipo_producto: null,
        observacion: null,
        estado: null,
      },
    };
  },
  async beforeMount() {
    await this.getRutasRegalias();
    await this.getProdLquidos();
    await this.getTiposProducto();
    await this.getTipoVehiculo();
    await this.actGetListas(28);
  },
  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      nombre: {
        required,
      },
    },
  },
  computed: {
    filteredRows() {
      const searchTerm = this.filter.toLowerCase();
      return this.bloqueSitios.filter((row) => {
        const nombre = row.nombre.toLowerCase();
        return nombre.includes(searchTerm);
      });
    },

    ...mapState("modOperacion", ["listas"]),
    ...mapState("modHidrocarburos", ["loading", "empresas", "nominacion"]),

    filteredRowsDetail() {
      return this.detalles.filter((det) => {
        const sitio = det.det_nominacion.sitio.nombre.toLowerCase();
        const empresa = det.empresa.razon_social.toLowerCase();
        const searchTerm = this.buscarDet.toLowerCase();
        return sitio.includes(searchTerm) || empresa.includes(searchTerm);
      });
    },
  },
  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modHidrocarburos", [
      "actGetBloqueSitios",
      "actGetEmpresasNom",
      "actUploadFile",
      "actDownloadFile",
      "actGetNominacion",
    ]),

    async getBloqueSitios() {
      await axios.get("api/hidrocarburos/bloques/sitios/").then((response) => {
        this.bloqueSitios = response.data;
      });
    },

    getIndex() {
      let me = this;
      me.accion = me.accion ? me.accion : me.$route.params.accion;
      if (me.accion == "Crear") {
        me.metodo = "post";
        me.form.tipo_producto = 1;
        me.form.estado = 1;
      }

      if (me.accion == "Editar") {
        me.form.id = me.form.id ? me.form.id : me.$route.params.nominacion_id;
        axios
          .get("api/hidrocarburos/nominacionesRegalias/show", {
            params: {
              id: me.form.id,
            },
          })
          .then(async (response) => {
            me.form = response.data.programacion;
            me.detNominaciones = response.data.detNominaciones;
          });

        me.metodo = "put";
      }
    },

    async getTiposProducto() {
      await axios.get("/api/lista/83").then(async (response) => {
        this.tipos_productoOption = response.data.filter(
          (item) => item.numeracion === 1
        );
      });
    },

    editData(item) {
      this.editedItem = item;
    },

    save() {
      this.cargando = true;
      axios({
        method: this.metodo,
        url: "/api/hidrocarburos/nominacionesRegalias/save",
        data: this.form,
      })
        .then((response) => {
          this.form.id = response.data.id;
          this.accion = "Editar";
          this.$swal({
            icon: "success",
            title: "La nominacíon se guardó correctamente.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    highlightMatches(texto) {
      var text = String(texto);
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return (
        text.replace(re, (matchedText) => `<strong>${matchedText}</strong>`) +
        '<i class="right fas fa-angle-left"></i>'
      );
    },

    async fileUpload() {
      let me = this;
      var formData = new FormData();
      const archivo = document.querySelector("#fileDocu");
      formData.append("file", archivo.files[0]);
      formData.append("nominacion_regalias_id", me.form.id);
      formData.append("fecha_inicial", me.form.fecha_inicial);
      formData.append("fecha_final", me.form.fecha_final);

      axios;
      await axios
        .post("/api/hidrocarburos/nominacionesRegalias/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (response) => {
          let result_content =
            response.data.error.length > 0
              ? `<div>
                <p class="text-danger">
                  <strong>La plantilla no se cargo.</strong>
                </p>
              </div>`
              : `<div>
                <p class="text-success">
                  <strong>La plantilla se cargo exitosamente.</strong>
                </p>
              </div>`;
          if (response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados.</h4>`;
            response.data.error.forEach(
              (err) => (result_content += `<div><small>${err}</small></div>`)
            );
          }
          this.cargando = false;
          await this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: response.data.error.length > 0 ? "error" : "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
            //width: "800px",
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              this.accion == "Editar";
              /* me.metodo = "put"; */
              await this.getIndex();
            }
          });
          //this.limpiarPlantilla();
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    closeNominacion() {
      if (this.form.estado == 2) {
        this.$swal({
          title: "Esta seguro de poner en estado definitivo la Nominación?",
          text: "No podra volver a editar la Nominación",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Definitivo",
        }).then((result) => {
          // <--
          if (result.value) {
            axios({
              method: this.metodo,
              url: "/api/hidrocarburos/nominacionesRegalias/save",
              data: this.form,
            }).then((response) => {
              this.$swal({
                icon: "success",
                title: "La Nominacón se cerró con exito",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              });
            });

            this.$router.push({ path: "/Hidrocarburos/NominacionesRegalias" });
          } else {
            this.form.estado = 1;
          }
        });
      }
    },

    downloadFile() {
      axios
        .get("/api/hidrocarburos/nominacionesRegalias/exportar")
        .then((response) => {
          let data = response.data;
          location.href = data.url;
        });
    },

    getDetalles() {
      axios
        .get("api/hidrocarburos/nominacion/getDetalles", {
          params: {
            nominacion_id: this.$route.params.id,
          },
        })
        .then((response) => {
          this.detalles = response.data;
        });
    },

    async getRutasRegalias() {
      await axios.get("api/admin/rutasEcopetrol/lista").then((response) => {
        this.arrListasForm.rutasRegalias = response.data;
      });
    },

    async getProdLquidos() {
      await axios.get("api/hidrocarburos/Productos/lista").then((response) => {
        this.arrListasForm.prodLiquidos = response.data;
      });
    },

    async getTipoVehiculo() {
      await axios
        .get("api/admin/tiposVehiculos/lista", {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then((response) => {
          this.arrListasForm.tipos_vehiculos = response.data;
        });
    },

    deleteDet(id) {
      axios
        .delete("/api/hidrocarburos/nominacionesRegalias/deleteDet/" + id)
        .then((response) => {
          this.getIndex();
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },

  async mounted() {
    await this.getIndex();
  },

  created() {
    this.getBloqueSitios();
  },
};
</script>
<style>
/* The heart of the matter */
.testimonial-group > .row {
  overflow-x: auto;
  scrollbar-color: rgba(57, 7, 238, 0.644) #3164dd9a;
  white-space: nowrap;
}
.testimonial-group > .row > .col-sm-4 {
  display: inline-block;
  float: none;
}
</style>
